import { makeStyles, Theme } from '@material-ui/core';

export const useModalCloseBtnStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'absolute',
    top: 5,
    right: 5,
    width: 40,
    height: 40,
    padding: 0,
    color: theme.palette.text.primary,
    border: 'none',

    [theme.breakpoints.up('md')]: {
      top: 20,
      right: 20,
    },

    '&:hover': {
      border: 'none',
    },
  },
}));
